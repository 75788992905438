import React from "react";
import AddTaskIcon from '@mui/icons-material/AddTask';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import {Button, Container, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.primary.main,
		"& .apply-banner": {
			"& .text-holder": {
				"& .title": {
					color: theme.palette.text.light,
					fontSize: theme.typography.h1,
					fontWeight: theme.typography.fontWeightExtraBold,
					marginBottom: theme.spacing(6),
				},
				"& .description": {
					color: theme.palette.text.light,
					margin: theme.spacing(0, "auto", 6, "auto"),
				},
				"& .button-holder": {
					"& .MuiButtonBase-root": {
						marginTop: theme.spacing(3),
						position: "relative",
						border: "none",
						background: "#fff",
						color: theme.palette.primary.main,
						boxShadow: "none",
						zIndex: 1,
						minWidth: "150px",
						padding: theme.spacing(2, 4),
						transition: theme.transitions.easing.easeOut,
						borderRadius: theme.shape.borderRadius4,
						"&::before": {
							content: "''",
							position: "absolute",
							left: 0,
							top: 0,
							height: "100%",
							width: "0%",
							opacity: 0,
							background: theme.palette.secondary.main,
							borderRadius: "8px 0 0 8px",
							transition: theme.transitions.easing.easeOut,
						},
						"&::after": {
							content: "''",
							position: "absolute",
							right: 0,
							top: 0,
							height: "100%",
							width: "0%",
							opacity: 0,
							background: theme.palette.secondary.main,
							borderRadius: "0 8px 8px 0",
							transition: theme.transitions.easing.easeOut,
						},
						"& span": {
							position: "relative",
							zIndex: 1,
							transition: theme.transitions.easing.easeOut,
						},
						"& .MuiSvgIcon-root": {
							fontSize: theme.typography.h3,
							marginRight: theme.spacing(1),
						},
						"&:hover": {
							color: "#fff",
							"&::before": {
								width: "50%",
								opacity: 1,
							},
							"&::after": {
								width: "50%",
								opacity: 1,
							},
						},
					},
				},
			},
		},
		"@media(max-width: 600px)": {
			padding: theme.spacing(10, 0),
			"& .apply-banner":{
				"& .text-holder":{
					"& .description":{
						width: "100%",
					},
				},
			},
		},
		"@media(min-width: 768px)": {
			padding: theme.spacing(10, 0),
			"& .apply-banner":{
				"& .text-holder":{
					"& .description":{
						width: "100%",
					},
				},
			},
		},
		"@media(min-width: 992px)": {
			padding: theme.spacing(20, 0, 22, 0),
			"& .apply-banner":{
				"& .text-holder":{
					"& .description":{
						width: "60%",
					},
				},
			},
		},
	},
}));

const ApplyBanner = () => {
	const classes = useStyles();

	return (
		<section className={classes.root}>
			<Container className="apply-banner">
				<Typography component="div" className="text-holder" align="center">
					<Typography variant="h2" component="h2" className="title">
						What will you Discover?
					</Typography>
					<Typography variant="p" component="p" className="description">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid aperiam blanditiis
						consectetur facere nam nulla obcaecati officia quaerat quibusdam?
					</Typography>
					<Typography component="div" className="button-holder">
						<Button component={Link} to="#" variant="contained" sx={{mr: 2}}>
							<Typography variant="span" component="span" display="flex" alignItems="center">
								<AddTaskIcon/> View Courses
							</Typography>
						</Button>
						<Button component={Link} to="#" variant="contained">
							<Typography variant="span" component="span" display="flex" alignItems="center">
								<PersonAddAltRoundedIcon/> Start Trail
							</Typography>
						</Button>
					</Typography>
				</Typography>
			</Container>
		</section>
	)
}

export default ApplyBanner;