import React from "react";
import {makeStyles} from "@mui/styles";
import {Container, Typography} from "@mui/material";
import PriceTab from "./Price-tab";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .price-plan": {
			"& .text-holder": {
				marginBottom: theme.spacing(10),
				"& .title": {
					fontSize: theme.typography.h1,
					fontWeight: theme.typography.fontWeightBold,
					color: theme.palette.primary.main,
				},
			},
		},
		"@media(max-width: 600px)": {
			padding: theme.spacing(10, 0),
		},
		"@media(min-width: 768px)": {
			padding: theme.spacing(10, 0),
		},
		"@media(min-width: 992px)": {
			padding: theme.spacing(20, 0, 0),
		},
	},
}));

const PricePlan = () => {
	const classes = useStyles();
	return (
		<section className={classes.root}>
			<Container className="price-plan">
				<Typography component="div" className="text-holder" align="center">
					<Typography variant="h2" component="h2" className="title">
						Simple All Inclusive Pricing
					</Typography>
				</Typography>
				<PriceTab/>
			</Container>
		</section>
	)
}

export default PricePlan;