import React from "react";
import Image from "./../../Assets/Images/bg2.jpg";
import Image2 from "./../../Assets/Images/bg.jpg";
import Slider from "react-slick";
import {Button, Container, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .slider-list": {
			"& .slick-prev": {
				zIndex: 1,
				"&::before": {
					content: "\\f177",
					font: "normal normal normal 32px/1 FontAwesome",
				},
			},
			"& .slick-next": {
				zIndex: 1,
				"&::before": {
					content: "\\f178",
					font: "normal normal normal 32px/1 FontAwesome",
				},
			},
			"& .slider-item": {
				"& .img-holder": {
					width: "100%",
					"& .bg-stretch": {
						height: "100%",
						width: "100%",
						position: "relative",
						display: "flex",
						alignItems: "center",
						"&::before": {
							content: "''",
							position: "absolute",
							top: 0,
							left: 0,
							background: "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.6%, " +
								"rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, " +
								"rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, " +
								"rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, " +
								"rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.6%," +
								" rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%)",
							height: "100%",
							width: "100%",
						},
						"&::after": {
							content: "''",
							position: "absolute",
							bottom: 0,
							left: 0,
							background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.6%, " +
								"rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, " +
								"rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, " +
								"rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, " +
								"rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.6%," +
								" rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%)",
							height: "100%",
							width: "100%",
						},
						"& .content-holder": {
							position: "relative",
							zIndex: 1,
							"& .title": {
								color: theme.palette.text.light,
								marginBottom: theme.spacing(5),
							},
							"& .sub-title": {
								color: theme.palette.text.light,
								margin: theme.spacing(0, 0, 5, 0),
								lineHeight: 1.5,
							},
							"& .join-btn": {
								marginTop: theme.spacing(3),
								position: "relative",
								border: "none",
								background: theme.palette.primary.main,
								boxShadow: "none",
								zIndex: 1,
								minWidth: "150px",
								padding: theme.spacing(2, 4),
								transition: theme.transitions.easing.easeOut,
								borderRadius: theme.shape.borderRadius3,
								"&::before": {
									content: "''",
									position: "absolute",
									left: 0,
									top: 0,
									height: "100%",
									width: "0%",
									opacity: 0,
									background: theme.palette.secondary.main,
									borderRadius: "8px 0 0 8px",
									transition: theme.transitions.easing.easeOut,
								},
								"&::after": {
									content: "''",
									position: "absolute",
									right: 0,
									top: 0,
									height: "100%",
									width: "0%",
									opacity: 0,
									background: theme.palette.secondary.main,
									borderRadius: "0 8px 8px 0",
									transition: theme.transitions.easing.easeOut,
								},
								"& span":{
									position: "relative",
									zIndex: 1,
									transition: theme.transitions.easing.easeOut,
								},
								"& .MuiSvgIcon-root": {
									color: "#fff",
									fontSize: theme.typography.h3,
									marginLeft: theme.spacing(1),
								},
								"&:hover": {
									"&::before": {
										width: "50%",
										opacity: 1,
									},
									"&::after": {
										width: "50%",
										opacity: 1,
									},
								},
							},
						},
					},
				},
			},
		},
		"@media(max-width: 600px)": {
			marginTop: "78px",
			"& .slick-prev": {
				left: "40%",
				top: "90%",
			},
			"& .slick-next": {
				right: "40%",
				top: "90%",
			},
			"& .slider-item": {
				"& .img-holder": {
					height: "100%",
					"& .bg-stretch": {
						padding: theme.spacing(10, 0),
						"& .content-holder": {
							"& .title": {
								fontSize: theme.typography.h1,
								fontWeight: theme.typography.fontWeightBold,
								width: "100%",
							},
							"& .sub-title": {
								fontSize: theme.typography.h4,
								width: "100%",
							},
						},
					},
				},
			},
		},
		"@media(min-width: 768px)": {
			marginTop: "78px",
			"& .slider-item": {
				"& .img-holder": {
					height: "40vh",
					"& .bg-stretch": {
						"& .content-holder": {
							"& .title": {
								fontSize: theme.typography.h1,
								fontWeight: theme.typography.fontWeightBold,
								width: "100%",
							},
							"& .sub-title": {
								fontSize: theme.typography.h4,
								width: "100%",
							},
						},
					},
				},
			},
			"& .slick-prev": {
				left: "50%",
				top: "90%",
			},
			"& .slick-next": {
				right: "40%",
				top: "90%",
			},
		},
		"@media(min-width: 992px)": {
			marginTop: 0,
			"& .slider-item": {
				"& .img-holder": {
					height: "100vh",
					"& .bg-stretch": {
						"& .content-holder": {
							"& .title": {
								fontSize: theme.typography.customFont3,
								fontWeight: theme.typography.fontWeightBold,
								width: "70%",
							},
							"& .sub-title": {
								fontSize: theme.typography.h3,
								width: "70%",
							},
						},
					},
				},
			},
			"& .slick-prev": {
				left: "50%",
				top: "95%",
			},
			"& .slick-next": {
				right: "45%",
				top: "95%",
			},
		},
	},
}));

const sliderData = [
	{
		title: "Lorem ipsum dolor sit amet.",
		caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam odio repellendus tenetur. Accusantium " +
			"amet asperiores at consectetur cum eius eligendi esse eveniet expedita harum inventore ipsum laudantium",
		image: Image,
	},
	{
		title: "Lorem ipsum dolor sit amet.",
		caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam odio repellendus tenetur. Accusantium " +
			"amet asperiores at consectetur cum eius eligendi esse eveniet expedita harum inventore ipsum laudantium ",
		image: Image2,
	},
];

const HomeSlider = () => {
	const classes = useStyles();
	const settings = {
		dots: false,
		fade: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	};
	return (
		<section className={classes.root}>
			<div className="slider-list">
				<Slider {...settings}>
					{
						sliderData.map((item, i) => {
							return (
								<Typography component="div" className="slider-item" key={i + "__"}>
									<Typography component="div" className="img-holder">
										<Typography component="div" className="bg-stretch background-image"
										            style={{backgroundImage: `url('${item.image}')`}}>
											<Container className="content-holder">
												<Typography variant="h2" component="h2" className="title">
													{item.title}
												</Typography>
												<Typography variant="h4" component="h4" className="sub-title">
													{item.caption}
												</Typography>
												<Button variant="contained" className="join-btn">
													<Typography variant="span" component="span">
														Request a Demo
													</Typography>
												</Button>
											</Container>
										</Typography>
									</Typography>
								</Typography>
							)
						})
					}
				</Slider>
			</div>
		</section>
	)
}

export default HomeSlider;