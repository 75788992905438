import React from "react";
import Image from "./../../Assets/Images/bg2.jpg";
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import {makeStyles} from "@mui/styles";
import {Container, Grid, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		background: "#ebebeb",
		"& .why-choose-us": {
			"& .text-holder": {
				marginBottom: theme.spacing(10),
				"& .title": {
					color: theme.palette.primary.main,
					fontSize: theme.typography.h1,
					fontWeight: theme.typography.fontWeightBold,
					marginBottom: theme.spacing(3),
				},
				"& .description": {
					width: "70%",
					margin: theme.spacing(0, "auto"),
					color: theme.palette.text.descriptionText,
				},
			},
			"& .MuiGrid-root": {
				"& .MuiGrid-item": {
					width: "100%",
					"&:nth-child(2)": {
						"& .why-choose-us-list": {
							"& .icon-holder": {
								background: "#c77dff",
							},
						},
						"& .why-choose-us-list2": {
							"& .icon-holder": {
								background: "#00bbf9",
							},
						},
					},
					"& .why-choose-us-list-item": {
						"& .why-choose-us-list": {
							boxShadow: theme.shadows[0],
							padding: theme.spacing(4),
							borderRadius: theme.shape.borderRadius5,
							"& .icon-holder": {
								background: theme.palette.primary.main,
								height: "50px",
								width: "50px",
								borderRadius: theme.shape.borderRadius8,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginBottom: theme.spacing(3),
								"& .MuiSvgIcon-root": {
									color: theme.palette.text.light,
								},
							},
							"& .content": {
								"& .why-choose-us-title": {
									color: theme.palette.text.gray,
									fontSize: theme.typography.h3,
									fontWeight: theme.typography.fontWeightBold,
									marginBottom: theme.spacing(2),
								},
								"& .why-choose-us-description": {
									color: theme.palette.text.descriptionText,
									fontSize: theme.typography.h4,
								},
							},
						},
						"& .why-choose-us-list2": {
							boxShadow: theme.shadows[0],
							padding: theme.spacing(4),
							borderRadius: theme.shape.borderRadius5,
							"& .icon-holder": {
								background: "#f7b801",
								height: "50px",
								width: "50px",
								borderRadius: theme.shape.borderRadius8,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginBottom: theme.spacing(3),
								"& .MuiSvgIcon-root": {
									color: theme.palette.text.light,
								},
							},
							"& .content": {
								"& .why-choose-us-title": {
									color: theme.palette.text.gray,
									fontSize: theme.typography.h3,
									fontWeight: theme.typography.fontWeightBold,
									marginBottom: theme.spacing(2),
								},
								"& .why-choose-us-description": {
									color: theme.palette.text.descriptionText,
									fontSize: theme.typography.h4,
								},
							},
						},
					},
					"& .why-choose-us-img": {
						"& .img-holder": {
							height: "378px",
							width: "100%",
							"& .bg-stretch": {
								height: "100%",
								width: "100%",
								borderRadius: theme.shape.borderRadius5,
							},
						},
					},
				},
			},
		},
		"@media(max-width: 600px)": {
			padding: theme.spacing(10, 0),
		},
		"@media(min-width: 768px)": {
			padding: theme.spacing(10, 0),
		},
		"@media(min-width: 992px)": {
			padding: theme.spacing(20, 0),
		},
	},
}));

const whyChooseUsData = [
	{
		title: "Lorem ipsum dolor sit amet.",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, soluta.",
		icon: <SettingsSuggestRoundedIcon/>
	},
	{
		title: "Lorem ipsum dolor sit amet.",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, soluta.",
		icon: <MenuBookRoundedIcon/>
	},
	{
		title: "Lorem ipsum dolor sit amet.",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, soluta.",
		icon: <SchoolRoundedIcon/>
	},
	{
		title: "Lorem ipsum dolor sit amet.",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, soluta.",
		icon: <StarBorderRoundedIcon/>
	},
];

const WhyChooseUs = () => {
	const classes = useStyles();

	return (
		<section className={classes.root}>
			<Container className="why-choose-us">
				<Typography component="div" className="text-holder" align="center">
					<Typography variant="h2" component="h2" className="title">
						Why Choose Us
					</Typography>
					<Typography variant="p" component="p" className="description">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam dolorum earum harum id
						incidunt ipsam ipsum, laudantium magni nihil voluptates?
					</Typography>
				</Typography>
				<Grid container spacing={3}>
					<Grid item lg={4} md={12} sm={12}>
						<Grid container spacing={3}>
							{
								whyChooseUsData.map((item, i) => {
									// eslint-disable-next-line array-callback-return
									if (i > 1) return;
									return (
										<Grid item lg={12} md={12} sm={12} key={i + "__"}
										      className="why-choose-us-list-item">
											<Typography component="div" className="why-choose-us-list">
												<Typography component="div" className="icon-holder">
													{item.icon}
												</Typography>
												<Typography component="div" className="content">
													<Typography variant="h4" component="h4"
													            className="why-choose-us-title">
														{item.title}
													</Typography>
													<Typography variant="h4" component="h4"
													            className="why-choose-us-description">
														{item.description}
													</Typography>
												</Typography>
											</Typography>
										</Grid>
									)
								})
							}
						</Grid>
					</Grid>
					<Grid item lg={4} md={12} sm={12}>
						<Typography component="div" className="why-choose-us-img">
							<Typography component="div" className="img-holder">
								<Typography component="div" className="bg-stretch background-image"
								            style={{backgroundImage: `url('${Image}')`}}/>
							</Typography>
						</Typography>
					</Grid>
					<Grid item lg={4} md={12} sm={12}>
						<Grid container spacing={3}>
							{
								whyChooseUsData.slice(2).map((item, i) => {
									return (
										<Grid item lg={12} md={12} sm={12} key={i + "__"}
										      className="why-choose-us-list-item">
											<Typography component="div" className="why-choose-us-list2">
												<Typography component="div" className="icon-holder">
													{item.icon}
												</Typography>
												<Typography component="div" className="content">
													<Typography variant="h4" component="h4"
													            className="why-choose-us-title">
														{item.title}
													</Typography>
													<Typography variant="h4" component="h4"
													            className="why-choose-us-description">
														{item.description}
													</Typography>
												</Typography>
											</Typography>
										</Grid>
									)
								})
							}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default WhyChooseUs;