import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles, useTheme} from '@mui/styles';
import Image from "./../../Assets/Images/consultancy.png";
import {Container} from "@mui/material";
import MobileMenu from './Mobile-menu';
// import Profile from "../Profile";
// import Login from "../Login";
// import Register from "../Register";

// style
const useStyles = makeStyles((theme) => ({
	headerBg: {
		position: 'absolute',
		zIndex: 2,
		background: "transparent",
		// boxShadow: theme.shadows[0],
		transition: theme.transitions.easing.easeOut,
		padding: theme.spacing(1),
		width: "100%",
	},
	'@media(max-width: 600px)': {
		headerBg: {
			display: 'none',
		},
	},
	'@media(min-width: 768px)': {
		headerBg: {
			display: 'none',
		},
	},
	'@media(min-width: 992px)': {
		headerBg: {
			display: 'block',
		},
	},
	headerMain: {
		position: 'relative',
		'& .content-holder': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			'& .logo-holder': {
				marginRight: '20px',
				'& img': {
					maxHeight: '60px',
				},
			},
			'& .navigation': {
				display: "flex",
				alignItems: "center",
				'& .main-navigation': {
					margin: 0,
					padding: theme.spacing(0),
					'& .main-navigation-list': {
						listStyle: 'none',
						display: 'inline-block',
						transition: "all .3s ease-out",
						padding: "2px 20px",
						'& .main-navigation-list-link': {
							textDecoration: 'none',
							color: theme.palette.text.light,
							fontSize: "15px",
							cursor: 'pointer',
							transition: "all .3s ease-out",
							position: "relative",
							"&::before": {
								content: "''",
								position: "absolute",
								top: "-10px",
								left: 0,
								right: 0,
								height: "2px",
								transform: "scaleX(0)",
								background: theme.palette.primary.main,
								transition: "transform 0.5s ease-out",
								transformOrigin: "center right",
							},
							"&::after": {
								position: "absolute",
								content: "''",
								left: 0,
								right: 0,
								bottom: "-10px",
								height: "2px",
								transform: "scaleX(0)",
								background: theme.palette.primary.main,
								transition: "transform 0.5s ease-out",
								transformOrigin: " center left",
							},
							'&:hover': {
								color: theme.palette.primary.main,
							},
						},
						'&:hover': {
							'& .main-navigation-list-link': {
								"&::before": {
									transform: "scaleX(1)",
									transformOrigin: "center left",
								},
								"&::after": {
									transform: "scaleX(1)",
									transformOrigin: "center right",
								},
							},
						},
					},
				},
			},
		},
	},
}));

const menuData = [
	{
		label: "Home",
	},
	{
		label: "About",
	},
	{
		label: "Program",
	},
	{
		label: "Gallery",
	},
	{
		label: "Contact Us",
	},
];

const Header = () => {
	const classes = useStyles();
	const theme = useTheme();
	window.onscroll = function () {
		scrollFunction();
	};
	let navStyle = '';
	let linkStyles = '';
	let loginStyle = '';
	let loginHoverStyle = '';
	let registerHoverStyle = '';
	let registerStyle = '';
	let linkClass = '.main-navigation-list-link';
	let loginClass = '.login-btn';
	let loginHoverClass = '.login-btn:hover';
	let registerHoverClass = '.register-btn:hover';
	let registerStyleClass = '.register-btn';

	function scrollFunction() {
		let className = `${classes.headerBg}`;
		if (
			document.body.scrollTop > 90 ||
			document.documentElement.scrollTop > 90
		) {
			navStyle = `{
                position: fixed !important; 
                background: rgba(255, 255, 255, 0.8) !important;
                z-index: 9 !important; top: 0; 
                backdrop-filter: blur(6px) !important;
                color: #434343 !important;
                left: 0 !important; 
                right: 0 !important; 
                animation: slideIn 2s; 
                transition: transform 1s ease-in-out 0s; 
                animation-timing-function: ease-out;
                animation-duration: .7s;
            }`;
			linkStyles = `{
			  color: #434343 !important;
			}`;
			loginStyle = `{
			  color: #434343 !important;
			  border: 1px solid #434343 !important;
			}`;
			loginHoverStyle = `{
			  color: #fff !important;
			  border: 1px solid ${theme.palette.primary.main} !important;
			}`;
			registerStyle = `{
			  color: #434343 !important;
			  border: 1px solid #434343 !important;
			}`;
			registerHoverStyle = `{
			  color: #fff !important;
			  border: 1px solid ${theme.palette.primary.main} !important;
			}`;

			let keyFrame = `@keyframes slideIn { 
                0% {
                    opacity: 0;
                    top: -30px;
                }
	            100% {
                    opacity: 1;
                    top: 0;
                } 
              }`;

			document.getElementById('nav-style').innerHTML =
				'.' + className + navStyle + linkClass + linkStyles + loginClass + loginStyle + registerStyleClass +
				registerStyle + loginHoverClass + loginHoverStyle + registerHoverClass + registerHoverStyle + keyFrame;
		} else {
			navStyle = `{ 
            }`;
			linkStyles = `{ 
            }`;
			loginStyle = `{ 
            }`;
			loginHoverStyle = `{ 
            }`;
			registerStyle = `{ 
            }`;
			registerHoverStyle = `{ 
            }`;

			document.getElementById('nav-style').innerHTML =
				'.' + className + navStyle + linkClass + linkStyles + loginClass + loginStyle +
				registerStyleClass + registerStyle + loginHoverClass + loginHoverStyle + registerHoverClass + registerHoverStyle;
		}
	}

	return (
		<>
			<MobileMenu/>
			<section className={`${classes.headerBg}`}>
				<Container className={`${classes.headerMain}`}>
					<div className="content-holder">
						<div className="logo-holder">
							<Link to="/">
								<img
									src={Image}
									alt="img"
								/>
							</Link>
						</div>
						<nav className="navigation">
							<ul className="main-navigation">
								{
									menuData.map((item, i) => {
										return (
											<li className="main-navigation-list" key={i + "__"}>
												<Link to="/" className="main-navigation-list-link">
													{item.label}
												</Link>
											</li>
										)
									})
								}
							</ul>
							{/*<Profile/>*/}
							{/*<Login/>*/}
							{/*<Register/>*/}
						</nav>
					</div>
				</Container>
			</section>
		</>
	);
};

export default Header;