import React from "react";
import {Link} from "react-router-dom";
import Image from "./../../Assets/Images/bg.jpg";
import Image2 from "./../../Assets/Images/bg2.jpg";
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import {makeStyles} from "@mui/styles";
import { Container, Divider, Grid, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .online-course": {
			"& .text-holder": {
				marginBottom: theme.spacing(10),
				"& .title": {
					color: theme.palette.primary.main,
					fontSize: theme.typography.h1,
					fontWeight: theme.typography.fontWeightBold,
					marginBottom: theme.spacing(3),
				},
			},
			"& .MuiGrid-root": {
				"& .online-course-item": {
					"& .online-course-list": {
						boxShadow: theme.shadows[0],
						background: "#fff",
						borderRadius: theme.shape.borderRadius5,
						transition: theme.transitions.easing.easeOut,
						"& .img-holder": {
							height: "250px",
							width: "100%",
							position: "relative",
							borderRadius: "15px 15px 0 0",
							overflow: "hidden",
							"& .bg-stretch": {
								height: "100%",
								width: "100%",
								borderRadius: "15px 15px 0 0",
								transition: theme.transitions.easing.easeOut3,
							},
							"& .badge":{
								position: "absolute",
								top: "20px",
								left: "20px",
								fontSize: theme.typography.h5,
								background: theme.palette.primary.main,
								borderRadius: theme.shape.borderRadius2,
								padding: theme.spacing(1, 2),
								color: theme.palette.text.light,
							},
						},
						"& .content-holder": {
							padding: theme.spacing(4),
							"& .lesson-divider": {
								marginBottom: theme.spacing(3),
								"& .lesson": {
									color: theme.palette.text.descriptionText,
									"& .MuiSvgIcon-root": {
										fontSize: theme.typography.h3,
										marginRight: theme.spacing(1),
									},
								},
								"& .star": {
									color: theme.palette.text.descriptionText,
									"& .MuiSvgIcon-root": {
										fontSize: theme.typography.h2,
										marginRight: theme.spacing(1),
										color: "#f7b801",
									},
								},
							},
							"& .online-course-title": {
								marginBottom: theme.spacing(3),
								"& a": {
									textDecoration: "none",
									color: theme.palette.text.gray,
									fontSize: theme.typography.h3,
									fontWeight: theme.typography.fontWeightBold,
									transition: theme.transitions.easing.easeOut,
									"&:hover": {
										color: theme.palette.primary.main,
									},
								},
							},
							"& .description": {
								color: theme.palette.text.descriptionText,
								marginBottom: theme.spacing(3),
							},
							"& .price-holder": {
								"& .price": {
									color: theme.palette.primary.main,
								},
								"& .read-more": {
									textDecoration: "none",
									color: theme.palette.text.gray,
									display: "flex",
									alignItems: "center",
									transition: theme.transitions.easing.easeOut,
									"& .MuiSvgIcon-root": {
										fontSize: theme.typography.h3,
										marginLeft: theme.spacing(1),
									},
									"&:hover":{
										color: theme.palette.primary.main,
									},
								},
							},
						},
						"&:hover":{
							transform: "translateY(-10px)",
							"& .img-holder":{
								"& a":{
									"& .bg-stretch":{
										transform: "scale(1.5)",
									},
								},
							},
						},
					},
				},
			},
		},
		"@media(max-width: 600px)": {
			padding: theme.spacing(10, 0),
		},
		"@media(min-width: 768px)": {
			padding: theme.spacing(10, 0),
		},
		"@media(min-width: 992px)": {
			padding: theme.spacing(20, 0),
		},
	},
}));

const onlineCourseData = [
	{
		title: "Lorem ipsum dolor sit amet",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum magnamnam quis suscipit ut voluptate?",
		price: "Rs. 5000",
		lesson: "50",
		image: Image,
		category: "Art & Design",
	},
	{
		title: "Lorem ipsum dolor sit amet",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum magnamnam quis suscipit ut voluptate?",
		price: "Rs. 58000",
		lesson: "50",
		image: Image2,
		category: "UX Design",
		bgColor: "#219ebc",
	},
	{
		title: "Lorem ipsum dolor sit amet",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum magnamnam quis suscipit ut voluptate?",
		price: "Rs. 10000",
		lesson: "50",
		image: Image,
		category: "Development",
		bgColor: "#06d6a0",
	},
	{
		title: "Lorem ipsum dolor sit amet",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum magnamnam quis suscipit ut voluptate?",
		price: "Rs. 50000",
		lesson: "50",
		image: Image2,
		category: "Marketing",
		bgColor: "#7209b7",
	},
	{
		title: "Lorem ipsum dolor sit amet",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum magnamnam quis suscipit ut voluptate?",
		price: "Rs. 10000",
		lesson: "50",
		image: Image,
		category: "Development",
		bgColor: "#06d6a0",
	},
	{
		title: "Lorem ipsum dolor sit amet",
		description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum magnamnam quis suscipit ut voluptate?",
		price: "Rs. 50000",
		lesson: "50",
		image: Image2,
		category: "Marketing",
		bgColor: "#7209b7",
	},
];

const OnlineCourse = () => {
	const classes = useStyles();

	return (
		<section className={classes.root}>
			<Container className="online-course">
				<Typography component="div" className="text-holder">
					<Typography variant="h2" component="h2" className="title">
						Find The Right <br/> Online Course For You
					</Typography>
				</Typography>
				<Grid container spacing={4}>
					{
						onlineCourseData.map((item, i) => {
							return (
								<Grid item lg={4} md={12} sm={12} className="online-course-item" key={i + "__"}>
									<Typography component="div" className="online-course-list">
										<Typography component="div" className="img-holder">
											<Link to="#">
												<Typography component="div" className="bg-stretch background-image"
												            style={{backgroundImage: `url('${item.image}')`}}/>
											</Link>
											<Typography component="div" className="badge" style={{background: `${item?.bgColor}`}}>
												{item.category}
											</Typography>
										</Typography>
										<Typography component="div" className="content-holder">
											<Typography component="div" className="lesson-divider" display="flex"
											            alignItems="center" justifyContent="space-between">
												<Typography component="div" className="lesson" display="flex"
												            alignItems="center">
													<AutoStoriesIcon/>{item.lesson}
												</Typography>
												<Typography component="div" className="star" display="flex"
												            alignItems="center">
													<StarRateRoundedIcon/>4.0
												</Typography>
											</Typography>
											<Typography component="div" className="online-course-title">
												<Link to="#">
													{item.title}
												</Link>
											</Typography>
											<Typography component="div" className="description">
												{item.description}
											</Typography>
											<Divider sx={{mb: 3}}/>
											<Typography component="div" className="price-holder" display="flex"
											            alignItems="center" justifyContent="space-between">
												<Typography component="div" className="price">
													{item.price}
												</Typography>
												<Link to="#" className="read-more">
													Read More <ArrowForwardRoundedIcon/>
												</Link>
											</Typography>
										</Typography>
									</Typography>
								</Grid>
							)
						})
					}
				</Grid>
			</Container>
		</section>
	)
}

export default OnlineCourse;






