import React from "react";
import {Link} from "react-router-dom";
import Image from "./../../Assets/Images/consultancy.png";
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PermPhoneMsgOutlinedIcon from '@mui/icons-material/PermPhoneMsgOutlined';
import {Container, Divider, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(10, 0),
		background: "#0e1133",
		"& .footer": {
			"& .MuiGrid-root": {
				"& .MuiGrid-item": {
					width: "100%",
					"& .footer-title": {
						fontSize: theme.typography.h2,
						fontWeight: theme.typography.fontWeightBold,
						color: theme.palette.text.light,
						marginBottom: theme.spacing(6),
						position: "relative",
						display: "inline-block",
						paddingBottom: theme.spacing(1),
						"&::before": {
							content: "''",
							position: "absolute",
							bottom: 0,
							left: "50%",
							transform: "translateX(-50%)",
							background: theme.palette.text.light,
							height: "1px",
							width: "50%",
						},
					},
					"& .logo-holder": {
						"& img": {
							maxHeight: "80px",
							marginBottom: theme.spacing(2),
						},
						"& .slogan": {
							color: theme.palette.text.light,
						},
					},
					"& .navigation": {
						marginBottom: theme.spacing(4),
						"& ul": {
							margin: theme.spacing(0),
							padding: theme.spacing(0),
							"& li": {
								listStyle: "none",
								"&:not(:last-child)": {
									marginBottom: theme.spacing(3),
								},
								"& a": {
									textDecoration: "none",
									display: "flex",
									alignItems: "center",
									color: theme.palette.text.light,
									transition: theme.transitions.easing.easeOut,
									"& .MuiSvgIcon-root": {
										fontSize: theme.typography.h3,
										marginRight: theme.spacing(1),
									},
									"&:hover": {
										color: theme.palette.primary.main,
									},
								},
							},
						},
					},
					"& .social-media": {
						"& ul": {
							margin: theme.spacing(0),
							padding: theme.spacing(0),
							"& li": {
								listStyle: "none",
								display: "inline-block",
								"&:not(:last-child)": {
									marginRight: theme.spacing(3),
								},
								"& a": {
									textDecoration: "none",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									color: theme.palette.text.light,
									transition: theme.transitions.easing.easeOut,
									border: "1px solid #fff",
									height: "30px",
									width: "30px",
									borderRadius: theme.shape.borderRadius2,
									"& .MuiSvgIcon-root": {
										fontSize: theme.typography.h3,
										marginRight: theme.spacing(1),
										transition: theme.transitions.easing.easeOut,
									},
									"&:hover": {
										background: theme.palette.primary.main,
										"& .MuiSvgIcon-root": {
											transform: "scale(2)",
										},
									},
								},
							},
						},
					},
				},
			},
			"& .copy-right": {
				"& .copy-right-text": {
					color: theme.palette.text.light,
				},
			},
		},
	},
}));

const menuData = [
	{
		label: "Home",
	},
	{
		label: "About",
	},
	{
		label: "Program",
	},
	{
		label: "Gallery",
	},
	{
		label: "Contact Us",
	},
];

const contactData = {
	address: "Kathmandu, Nepal",
	email: "demo@gmal.com",
	phone: "1234567890",
};

const Footer = () => {
	const classes = useStyles();
	return (
		<section className={classes.root}>
			<Container className="footer">
				<Grid container spacing={3}>
					<Grid item lg={3} md={12} sm={12}>
						<Typography component="div" className="logo-holder">
							<Typography component="img" src={Image}/>
							<Typography component="div" className="slogan">
								Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus, necessitatibus.
							</Typography>
						</Typography>
					</Grid>
					<Grid item lg={3} md={12} sm={12}>
						<Typography variant="h2" component="h2" className="footer-title">
							About Us
						</Typography>
						<Typography component="div" className="navigation">
							<Typography component="ul">
								{
									menuData.map((item, i) => {
										return (
											<Typography component="li" key={i + "__"}>
												<Link to="#">
													<EastRoundedIcon/>
													{item.label}
												</Link>
											</Typography>
										)
									})
								}
							</Typography>
						</Typography>
					</Grid>
					<Grid item lg={3} md={12} sm={12}>
						<Typography variant="h2" component="h2" className="footer-title">
							Learning
						</Typography>
						<Typography component="div" className="navigation">
							<Typography component="ul">
								{
									menuData.map((item, i) => {
										return (
											<Typography component="li" key={i}>
												<Link to="#">
													<EastRoundedIcon/>
													{item.label}
												</Link>
											</Typography>
										)
									})
								}
							</Typography>
						</Typography>
					</Grid>
					<Grid item lg={3} md={12} sm={12}>
						{
							contactData ? (
								<>
									<Typography variant="h2" component="h2" className="footer-title">
										Contact Us
									</Typography>
									<Typography component="div" className="navigation">
										<Typography component="ul">
											<Typography component="li">
												{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
												<a href="#" target="_blank" rel="noreferrer">
													<LocationOnRoundedIcon/>
													{contactData.address}
												</a>
											</Typography>
											<Typography component="li">
												<a href={`mailto:${contactData.email}`} target="_blank"
												   rel="noreferrer">
													<EmailRoundedIcon/>
													{contactData.email}
												</a>
											</Typography>
											<Typography component="li">
												<a href={`tel:${contactData.phone}`} target="_blank" rel="noreferrer">
													<PermPhoneMsgOutlinedIcon/>
													{contactData.phone}
												</a>
											</Typography>
										</Typography>
									</Typography>
									<Typography component="div" className="social-media">
										<Typography component="ul">
											<Typography component="li">
												{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
												<a href="#" target="_blank" rel="noreferrer">
													<i className="fa fa-facebook"/>
												</a>
											</Typography>
											<Typography component="li">
												{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
												<a href="#" target="_blank" rel="noreferrer">
													<i className="fa fa-twitter"/>
												</a>
											</Typography>
											<Typography component="li">
												{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
												<a href="#" target="_blank" rel="noreferrer">
													<i className="fa fa-youtube-play"/>
												</a>
											</Typography>
										</Typography>
									</Typography>
								</>
							) : null
						}

					</Grid>
				</Grid>
				<Divider sx={{background: "#fff", marginTop: "50px", marginBottom: "50px",}}/>
				<Typography component="div" className="copy-right" align="center">
					<Typography component="div" className="copy-right-text">
						©{new Date().getFullYear()} Demo Pvt. Ltd. All rights reserved.
					</Typography>
				</Typography>
			</Container>
		</section>
	)
}
export default Footer;