import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Pages/Home";
import {ThemeProvider} from "@mui/styles";
import generateTheme from "./Function/Generate-theme";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import './App.css';

function App() {
	const theme = generateTheme();
	return (
		<div className="wrapper">
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Header/>
					<Routes>
						<Route path="/" element={<Home/>}/>
					</Routes>
					<Footer/>
				</BrowserRouter>
			</ThemeProvider>
		</div>
	);
}

export default App;
