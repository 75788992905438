import React from "react";
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import {Box, Button, Container, Grid, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: "100px",
		"& .subscribe-news-letter": {
			"& .MuiGrid-root": {
				"& .MuiGrid-item": {
					"& .content-holder": {
						"& .sub-title": {
							color: theme.palette.primary.main,
							fontSize: theme.typography.h4,
							fontWeight: theme.typography.fontWeightSemiBold,
							marginBottom: theme.spacing(3),
						},
						"& .title": {
							color: theme.palette.text.gray,
							fontSize: theme.typography.h1,
							fontWeight: theme.typography.fontWeightBold,
							marginBottom: theme.spacing(3),
						},
						"& .description": {
							color: theme.palette.text.descriptionText,
							fontSize: theme.typography.h3,
						},
					},
					"& .news-letter": {
						background: theme.palette.primary.main,
						padding: theme.spacing(7, 4),
						borderRadius: theme.shape.borderRadius5,
						"& .sub-title": {
							color: theme.palette.text.light,
							fontSize: theme.typography.h4,
							marginBottom: theme.spacing(3),
						},
						"& .MuiBox-root": {
							"& .MuiFormControl-root": {
								margin: theme.spacing(0),
								"& .MuiFormLabel-root": {
									color: theme.palette.text.light,
									transform: "translate(14px, 12px) scale(1)",
								},
								"& .MuiInputBase-root": {
									borderRadius: theme.shape.borderRadius4,
									color: "#fff",
									"& .MuiInputBase-input": {
										padding: "12px 14px",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										borderColor: "#fff",
									},
								},
							},
							"& .subscribe-btn": {
								marginLeft: theme.spacing(5),
								padding: "9px 14px",
								minWidth: "150px",
								boxShadow: "none",
								border: "2px solid #fff",
								borderRadius: theme.shape.borderRadius4,
							},
						},
					},
				},
			},
		},
	},
}));

const SubscribeNewsLetter = () => {
	const classes = useStyles();

	return (
		<section className={classes.root}>
			<Container className="subscribe-news-letter">
				<Grid container spacing={3}>
					<Grid item lg={6} md={12} sm={12}>
						<Typography component="div" className="content-holder">
							<Typography variant="h4" component="h4" className="sub-title">
								#NEWSLETTER
							</Typography>
							<Typography variant="h2" component="h2" className="title">
								Tutor’s Purpose is to Transform Access to Education.
							</Typography>
							<Typography variant="p" component="p" className="description">
								Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							</Typography>
						</Typography>
					</Grid>
					<Grid item lg={6} md={12} sm={12}>
						<Typography component="div" className="news-letter">
							<Typography variant="h4" component="h4" className="sub-title">
								Never miss a course.
							</Typography>
							<Box
								component="form"
								noValidate
								autoComplete="off"
							>
								<Typography component="div" className="divider" display="flex" alignItems="center">
									<TextField id="outlined-basic" variant="outlined" placeholder="Enter email" fullWidth/>
									<Button variant="contained" type="submit" className="subscribe-btn">
										<NotificationsNoneRoundedIcon/> Subscribe
									</Button>
								</Typography>
							</Box>
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default SubscribeNewsLetter;