import React from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {Container, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		zIndex: 1,
		"& .counter-component": {
			background: theme.palette.secondary.main,
			padding: theme.spacing(10),
			borderRadius: theme.shape.borderRadius5,
			"& .MuiGrid-root": {
				"& .MuiGrid-item": {
					textAlign: "center",
					width: "100%",
					"& .counter-number": {
						color: theme.palette.text.light,
						fontSize: theme.typography.h1,
						fontWeight: theme.typography.fontWeightBold,
					},
					"& .counter-title": {
						color: theme.palette.text.light,
						fontSize: theme.typography.h3,
					},
				},
			},
		},
		"@media(max-width:600px)": {
			margin: theme.spacing(10, 3),
		},
		"@media(min-width:768px)": {
			margin: theme.spacing(10, 3),
		},
		"@media(min-width:992px)": {
			marginTop: "-50px",
			marginBottom: "100px",
		},
	},
}));

VisibilitySensor.propTypes = {
	offset: PropTypes.shape({bottom: PropTypes.number}),
	partialVisibility: PropTypes.bool,
	children: PropTypes.func
};

const counterData = [
	{
		number: "50+",
		title: "Courses & videos",
	},
	{
		number: "265+",
		title: "Expert teachers",
	},
	{
		number: "1000+",
		title: "Students",
	},
	{
		number: "100+",
		title: "Classes complete",
	},
];

const CounterComponent = () => {
	const classes = useStyles();

	return (
		<section className={classes.root}>
			<Container className="counter-component">
				<Grid container spacing={3}>
					{
						counterData.map((item, i) => {
							const lastItem = item.number.slice(item.number.length - 1) + "";
							const addElement = (lastItem === "%" || lastItem === "+") ? lastItem : "";
							const figure = item.number.replace("+", "").replace("%", "");
							return (
								<Grid item lg={3} md={6} sm={12} key={i + "_"}>
									<VisibilitySensor partialVisibility offset={{bottom: 200}}>
										{({isVisible}) => (
											<div style={{height: 40}} className="counter-number">
												{isVisible ? <CountUp end={Number(figure)}/> : null}
												<Typography variant="span" component="span" className={addElement}>
													{addElement}
												</Typography>
											</div>
										)}
									</VisibilitySensor>
									<Typography component="div" className="counter-title">
										{item.title}
									</Typography>
								</Grid>
							)
						})
					}
				</Grid>
			</Container>
		</section>
	)
}

export default CounterComponent;