import React, {Component} from 'react';
import Image from "./../../../Assets/Images/consultancy.png";
import {push as Menu} from 'react-burger-menu';
import {Link} from 'react-router-dom';
import {withStyles} from '@mui/styles';
// import Profile from "../../Profile";
// import Login from "../../Login";
// import Register from "../../Register";

// style
const styles = (theme) => ({
	mobileMenuBg: {
		background: '#fff',
		padding: '5px 20px 5px 10px',
		position: 'fixed',
		zIndex: '9',
		left: 0,
		right: 0,
		top: 0,
		borderBottom: `2px solid ${theme.palette.primary.main}`,
	},
	'@media(max-width: 600px)': {
		mobileMenuBg: {
			display: 'block',
		},
	},
	'@media(min-width: 768px)': {
		mobileMenuBg: {
			display: 'block',
		},
	},
	'@media(min-width: 992px)': {
		mobileMenuBg: {
			display: 'none',
		},
	},
	mobileMenuMain: {
		'& .content-holder': {
			"& .logo-divider": {
				display: "flex",
				alignItems: "center",
				justifyContent: 'space-between',
				'& .burger-menu': {
					position: 'relative',
					paddingRight: '40px',
					'& .bm-burger-button': {
						position: 'absolute',
						width: '20px',
						height: '17px',
						left: '10px',
						top: '50%',
						transform: 'translate(0, -50%)',
						outline: 'none',
					},
					'& #react-burger-menu-btn': {
						outline: 'none',
					},
					'& .bm-burger-bars': {
						background: theme.palette.primary.main,
						height: '12% !important',
					},
					'& .bm-burger-bars-hover': {
						background: theme.palette.primary.main,
						opacity: 1,
					},
					'& .bm-cross-button': {
						height: '24px',
						width: '24px',
					},
					'& .bm-cross': {
						background: '#fff',
					},
					'& .bm-menu-wrap': {
						position: 'fixed',
						height: '100%',
						left: '0',
						top: '0',
					},
					'& .bm-menu': {
						background: theme.palette.primary.main,
						padding: '2.5em 1.5em 0',
						fontSize: '1.15em',
					},
					'& .bm-morph-shape': {
						fill: '#373a47',
					},
					'& .bm-item-list': {
						color: '#b8b7ad',
						padding: '0.8em',
					},
					'& .bm-item': {
						display: 'inline-block',
						margin: '0',
						padding: '0',
						outline: 'none',
					},
					'& .bm-overlay': {
						background: 'rgba(0, 0, 0, 0.3)',
						top: '0',
						left: '0',
					},
					'& nav': {
						'& ul': {
							margin: '0',
							padding: '0',
							'& .dropdown': {
								position: 'relative',
								transition: 'all .3s ease-out',
								listStyle: 'none',
								'&:not(:last-child)': {
									marginBottom: '20px',
								},
								'& .dropbtn': {
									fontSize: '16px',
									border: 'none',
									cursor: 'pointer',
									textDecoration: 'none',
									transition: 'all 0.3s ease-out',
									background: 'transparent',
									color: '#fff',
									position: 'relative',
									'& .fa': {
										marginLeft: '10px',
									},
								},
								'& .dropdown-content': {
									display: 'none',
									backgroundColor: '#f9f9f9',
									width: '100%',
									transition: 'all .3s ease-out',
									animation: 'opacity1 .5s',
									borderTop: `1px solid ${theme.palette.primary.main}`,
									'& .nav-dropdown-item': {
										listStyle: 'none',
										borderBottom: `1px solid ${theme.palette.primary.main}`,
										position: 'relative',
										'&:last-child': {
											borderBottom: 'none',
										},
										'& .main-dropdown-list-link': {
											color: '#434343',
											padding: '10px',
											textDecoration: 'none',
											display: 'block',
											transition: 'all .3s ease-out',
											'&:hover': {
												background: theme.palette.primary.main,
												paddingLeft: '25px',
												color: '#fff',
											},
										},
										'& .menu-line': {
											display: 'none',
											backgroundColor: '#f9f9f9',
											transition: 'all .3s ease-out',
											animation: 'opacity1 .5s',
											'& .menu-line-item': {
												listStyle: 'none',
												borderBottom: `1px solid ${theme.palette.primary.main}`,
												'&:last-child': {
													borderBottom: 'none',
												},
												'& a': {
													color: '#434343',
													padding: '10px 10px 10px 15px',
													marginLeft: '10px',
													textDecoration: 'none',
													display: 'block',
													transition: 'all .3s ease-out',
													position: 'relative',
													"&::before": {
														content: '""',
														position: 'absolute',
														top: '50%',
														left: '0',
														transform: 'translateY(-50%)',
														width: '8px',
														height: '8px',
														borderRadius: '50%',
														backgroundColor: theme.palette.primary.main,
													},
												},
											},
										},
										'&:hover': {
											'& .menu-line': {
												display: 'block',
											},
										},
									},
								},
								'&:hover': {
									'& .dropdown-content': {
										display: 'block',
									},
								},
							},
						},
					},
				},
				'& .logo-holder': {
					marginRight: '20px',
					'& img': {
						maxHeight: '60px',
					},
				},
			},
		},
	},
});

class MobileMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false,
		};
	}

	handleStateChange(state) {
		this.setState({menuOpen: state.isOpen});
	}

	closeMenu() {
		this.setState({menuOpen: false});
	}

	toggleMenu() {
		this.setState((state) => ({menuOpen: !state.menuOpen}));
	}

	render() {
		const {classes} = this.props;

		const menuData = [
			{
				label: "Home",
			},
			{
				label: "About",
			},
			{
				label: "Program",
			},
			{
				label: "Gallery",
			},
			{
				label: "Contact Us",
			},
		];

		return (
			<section className={`${classes.mobileMenuBg}`}>
				<div className={`container-fluid ${classes.mobileMenuMain}`}>
					<div className="content-holder">
						<div className="logo-divider">
							<div className="burger-menu">
								<Menu
									disableAutoFocus
									isOpen={this.state.menuOpen}
									onStateChange={(state) => this.handleStateChange(state)}
								>
									<nav id="nav">
										<ul>
											{menuData.map((item, i) => {
												return (
													<li
														className="dropdown"
														key={i + "__"}
														onClick={() => this.closeMenu()}
													>
														<Link
															to="#"
															className="dropbtn"
														>
															{item.label}
														</Link>
													</li>
												)
											})}
										</ul>
									</nav>
								</Menu>
							</div>
							<div className="logo-holder">
								<Link to="/">
									<img src={Image} alt="img"/>
								</Link>
							</div>
							{/*<Profile/>*/}
							{/*<Login/>*/}
							{/*<Register/>*/}
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default withStyles(styles, {withTheme: true})(MobileMenu);