import React from "react";
import Image from "./../../Assets/Images/art.svg";
import Image2 from "./../../Assets/Images/tube.svg";
import Image3 from "./../../Assets/Images/business.svg";
import Image4 from "./../../Assets/Images/globe.svg";
import Image5 from "./../../Assets/Images/math.svg";
import Image6 from "./../../Assets/Images/medical.svg";
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import {makeStyles} from "@mui/styles";
import {Button, Container, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .course-block": {
			"& .MuiGrid-root": {
				"& .MuiGrid-item": {
					width: "100%",
					"& a": {
						textDecoration: "none",
						"& .service-block-list": {
							boxShadow: theme.shadows[0],
							borderRadius: theme.shape.borderRadius5,
							padding: theme.spacing(4),
							display: "flex",
							alignItems: "center",
							transition: theme.transitions.easing.easeOut,
							"& .icon-holder": {
								height: "50px",
								width: "50px",
								marginRight: theme.spacing(3),
								transition: theme.transitions.easing.easeOut,
							},
							"& .description": {
								color: theme.palette.text.descriptionText,
								fontSize: theme.typography.h3,
								fontWeight: theme.typography.fontWeightBold,
								transition: theme.transitions.easing.easeOut,
							},
							"&:hover": {
								background: `${theme.palette.primary.main}4f`,
								transform: "translateY(-10px)",
								"& .description": {
									color: theme.palette.text.gray,
								},
							},
						},
					},
					"& .course-block-list": {
						position: "relative",
						zIndex: 1,
						"& .course-title": {
							position: "relative",
							zIndex: 1,
							fontSize: theme.typography.h1,
							color: theme.palette.text.light,
							fontWeight: theme.typography.fontWeightBold,
							marginBottom: theme.spacing(5),
						},
						"& .course-content": {
							position: "relative",
							zIndex: 1,
							"& .course-item": {
								fontSize: theme.typography.h4,
								color: theme.palette.text.light,
								fontWeight: theme.typography.fontWeightMedium,
								marginBottom: theme.spacing(4),
								paddingLeft: theme.spacing(4),
								position: "relative",
								"&::before": {
									content: "''",
									position: "absolute",
									left: 0,
									top: "50%",
									transform: "translate(0, -50%)",
									height: "8px",
									width: "8px",
									background: theme.palette.secondary.main,
									border: `2px solid ${theme.palette.text.light}`,
									borderRadius: theme.shape.borderRadius8,
								},
							},
						},
						"& .MuiButtonBase-root": {
							marginTop: theme.spacing(3),
							position: "relative",
							border: "1px solid #fff",
							background: "transparent",
							boxShadow: "none",
							zIndex: 1,
							minWidth: "150px",
							padding: theme.spacing(2, 4),
							transition: theme.transitions.easing.easeOut,
							borderRadius: theme.shape.borderRadius3,
							"&::before": {
								content: "''",
								position: "absolute",
								left: 0,
								top: 0,
								height: "100%",
								width: "0%",
								opacity: 0,
								background: theme.palette.secondary.main,
								borderRadius: "8px 0 0 8px",
								transition: theme.transitions.easing.easeOut,
							},
							"&::after": {
								content: "''",
								position: "absolute",
								right: 0,
								top: 0,
								height: "100%",
								width: "0%",
								opacity: 0,
								background: theme.palette.secondary.main,
								borderRadius: "0 8px 8px 0",
								transition: theme.transitions.easing.easeOut,
							},
							"& span": {
								position: "relative",
								zIndex: 1,
								transition: theme.transitions.easing.easeOut,
							},
							"& .MuiSvgIcon-root": {
								color: "#fff",
								fontSize: theme.typography.h3,
								marginLeft: theme.spacing(1),
							},
							"&:hover": {
								"&::before": {
									width: "50%",
									opacity: 1,
								},
								"&::after": {
									width: "50%",
									opacity: 1,
								},
							},
						},
					},
				},
			},
		},
		"@media(max-width: 600px)": {
			padding: theme.spacing(10, 0),
			"& .course-block-list": {
				background: theme.palette.primary.main,
				padding: theme.spacing(6),
				borderRadius: theme.shape.borderRadius5,
			},
		},
		"@media(min-width: 768px)": {
			padding: theme.spacing(10, 0),
			"& .course-block-list": {
				background: theme.palette.primary.main,
				padding: theme.spacing(6),
				borderRadius: theme.shape.borderRadius5,
			},
		},
		"@media(min-width: 992px)": {
			padding: theme.spacing(20, 0),
			"& .course-block-list": {
				marginTop: "-300px",
				padding: "140px 30px 30px",
				background: "transparent",
				borderRadius: theme.shape.borderRadius,
				"&::before": {
					content: "''",
					position: "absolute",
					top: 0,
					height: "100%",
					width: "100%",
					background: theme.palette.primary.main,
					clipPath: "polygon(0 17%,100% 0,100% 100%,0% 100%)",
					left: 0,
					zIndex: 1,
				},
				"&::after": {
					content: "''",
					position: "absolute",
					top: "-10px",
					left: "-10px",
					height: "100%",
					width: "100%",
					background: theme.palette.secondary.main,
					clipPath: "polygon(0 17%,100% 0,100% 100%,0% 100%)",
					zIndex: -1,
				},
			},
		},
	},
}));

const courseBlockData = [
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
	{
		title: "Lorem ipsum dolor sit amet.",
	},
];

const relatedCourse = [
	{
		title: "Design",
		image: Image,
	},
	{
		title: "Chemistry",
		image: Image2,
	},
	{
		title: "Business",
		image: Image3,
	},
	{
		title: "Language",
		image: Image4,
	},
	{
		title: "Mathematics",
		image: Image5,
	},
	{
		title: "Medical",
		image: Image6,
	},
];

const CourseBlock = () => {
	const classes = useStyles();

	return (
		<section className={classes.root}>
			<Container className="course-block">
				<Grid container spacing={3}>
					<Grid item lg={8} md={12} sm={12}>
						<Grid container spacing={3}>
							{
								relatedCourse.map((item, i) => {
									if (i > 5) return;
									return (
										<Grid item lg={6} md={12} sm={12} key={i + "__"}>
											<Link to="#">
												<Typography component="div" className="service-block-list"
												            display="flex">
													<Typography component="img" src={item.image} className="icon-holder"
													            sx={{mr: 2}}/>
													<Typography component="div" className="content">
														<Typography variant="p" component="p" className="description">
															{item.title}
														</Typography>
													</Typography>
												</Typography>
											</Link>
										</Grid>
									)
								})
							}
						</Grid>
					</Grid>
					<Grid item lg={4} md={12} sm={12}>
						<Typography component="div" className="course-block-list">
							<Typography component="h2" variant="h2" className="course-title">
								Featured topics by category
							</Typography>
							{
								courseBlockData.map((item, i) => {
									return (
										<Typography component="div" className="course-content" key={i + "__"}>
											<Typography variant="h4" component="h4" className="course-item">
												{item.title}
											</Typography>
										</Typography>
									)
								})
							}
							<Button variant="contained">
								<Typography variant="span" component="span" display="flex" alignItems="center">
									View More
									<EastRoundedIcon/>
								</Typography>
							</Button>
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default CourseBlock;