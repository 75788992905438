import React from "react";
import HomeSlider from "../Components/Home-slider";
import CourseBlock from "../Components/Course-block";
import WhyChooseUs from "../Components/Why-choose-us";
import OnlineCourse from "../Components/Online-course";
import ApplyBanner from "../Components/Apply-banner";
import CounterComponent from "../Components/Counter-component";
import SubscribeNewsLetter from "../Components/Subscribe-news-letter";
import PricePlan from "../Components/Price-plan";

const Home = () =>{
	return(
		<main>
			<HomeSlider/>
			<CourseBlock/>
			<WhyChooseUs/>
			<PricePlan/>
			<OnlineCourse/>
			<ApplyBanner/>
			<CounterComponent/>
			<SubscribeNewsLetter/>
		</main>
	)
}

export default Home;