import React from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {makeStyles} from "@mui/styles";
import {Button, Grid} from "@mui/material";

const useStyles = makeStyles((theme) => ({
	priceTab: {
		"& .MuiBox-root": {
			"& .MuiTabs-root": {
				overflow: "visible !important",
				"& .MuiTabs-scroller": {
					overflow: "visible !important",
					"& .MuiTabs-indicator": {
						display: "none",
					},
					"& .MuiTabs-flexContainer": {
						"& .MuiButtonBase-root": {
							borderRadius: theme.shape.borderRadius3,
							minHeight: "unset",
							minWidth: "150px",
							background: "#f3f4f8",
							boxShadow: theme.shadows[0],
							transition: theme.transitions.easing.easeOut,
							"&:not(:last-child)": {
								marginRight: theme.spacing(3),
							},
						},
						"& .Mui-selected": {
							background: theme.palette.primary.main,
							color: "#fff",
							boxShadow: theme.shadows[1],
						},
					},
				},
			},
		},
		"& .MuiGrid-root": {
			"& .MuiGrid-item": {
				width: "100%",
				"& .price-list": {
					padding: theme.spacing(10),
					background: "#f3f4f8",
					borderRadius: theme.shape.borderRadius5,
					position: "relative",
					overflow: "hidden",
					"&::before":{
						content: "''",
						position: "absolute",
						top: "-20px",
						right: "-40px",
						background: theme.palette.primary.main,
						height: "300px",
						width: "120px",
						transform: "rotate(-20deg)",
						transformOrigin: "top left",

					},
					"&::after":{
						content: "''",
						position: "absolute",
						bottom: "-25px",
						left: "-150px",
						background: theme.palette.secondary.main,
						height: "300px",
						width: "120px",
						transform: "rotate(-20deg)",
						transformOrigin: "top right",

					},
					"& .price-list-header": {
						fontSize: theme.typography.h1,
						fontWeight: theme.typography.fontWeightBold,
						color: theme.palette.primary.main,
						marginBottom: theme.spacing(3),
					},
					"& ul": {
						margin: theme.spacing(0, 0, 3),
						padding: theme.spacing(0),
						"& li": {
							listStyle: "none",
							display: "flex",
							alignItems: "center",
							color: theme.palette.text.descriptionText,
							"&:not(:last-child)": {
								marginBottom: theme.spacing(3),
							},
							"& .MuiSvgIcon-root": {
								color: "#06d6a0",
								marginRight: theme.spacing(1),
							},
						},
					},
					"& .price-value": {
						fontSize: theme.typography.customFont2,
						fontWeight: theme.typography.fontWeightBold,
						color: theme.palette.text.gray,
						marginBottom: theme.spacing(3),
					},
					"& .MuiButtonBase-root":{
						background: theme.palette.secondary.main,
						boxShadow: theme.shadows[2],
						borderRadius: theme.shape.borderRadius3,
						minWidth: "120px",
						textAlign:"center",
						margin: "0 auto",
					}
				},
			},
		},
	},
}));

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<>
					{children}
				</>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const priceTable = [
	{
		title: "Standard",
		price: "Rs. 5000",
		facilities: [
			{
				title: "Course Discussions",
			},
			{
				title: "Content Library",
			},
			{
				title: "1-hour Mentorship",
			},
			{
				title: "Online Course",
			},
		],
	},
	{
		title: "Gold",
		price: "Rs. 10000",
		facilities: [
			{
				title: "Course Discussions",
			},
			{
				title: "Content Library",
			},
			{
				title: "1-hour Mentorship",
			},
			{
				title: "Online Course",
			},
		],
	},
	{
		title: "Diamond",
		price: "Rs. 20000",
		facilities: [
			{
				title: "Course Discussions",
			},
			{
				title: "Content Library",
			},
			{
				title: "1-hour Mentorship",
			},
			{
				title: "Online Course",
			},
		],
	},
];

const PriceTab = () => {
	const [value, setValue] = React.useState(0);
	const classes = useStyles();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<section className={classes.priceTab}>
			<Box display="flex" alignItems="center" justifyContent="center" sx={{marginBottom: "50px"}}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					<Tab label="Monthly Plan" {...a11yProps(0)} />
					<Tab label="Annual Plan" {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<Grid container spacing={4}>
					{
						priceTable.map((item, i) => {
							return (
								<Grid item lg={4} md={12} sm={12} key={i + "__"}>
									<Typography component="div" className="price-list">
										<Typography variant="h4" component="h4" className="price-list-header">
											{item.title}
										</Typography>
										<Typography component="div" className="pricing-content">
											<Typography component="ul">
												{
													item?.facilities.map((facilitiesItem, i) => {
														return (
															<Typography component="li" key={i}>
																<CheckRoundedIcon/>
																{facilitiesItem.title}
															</Typography>
														)
													})
												}
											</Typography>
										</Typography>
										<Typography component="div" className="price-value">{item.price}</Typography>
										<Button variant="contained">
											Sign up
										</Button>
									</Typography>
								</Grid>
							)
						})
					}
				</Grid>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Grid container spacing={4}>
					{
						priceTable.map((item, i) => {
							return (
								<Grid item lg={4} md={12} sm={12} key={i + "__"}>
									<Typography component="div" className="price-list">
										<Typography variant="h4" component="h4" className="price-list-header">
											{item.title}
										</Typography>
										<Typography component="div" className="pricing-content">
											<Typography component="ul">
												{
													item?.facilities.map((facilitiesItem, i) => {
														return (
															<Typography component="li" key={i}>
																<CheckRoundedIcon/>
																{facilitiesItem.title}
															</Typography>
														)
													})
												}
											</Typography>
										</Typography>
										<Typography component="div" className="price-value">{item.price}</Typography>
										<Button variant="contained">
											Sign up
										</Button>
									</Typography>
								</Grid>
							)
						})
					}
				</Grid>
			</TabPanel>
		</section>
	);
}

export default PriceTab;
